import { openInNewTab } from './helpers';
import { LOCALES } from './localization/constants';

export const getFreshdeskWidgetLocale = () => {
  if (typeof window === 'undefined') return 'en';

  switch (window?.localizationData?.locale) {
    case LOCALES.SPANISH_MX:
      return 'es';
    case LOCALES.PORTUGUESE_BR:
      return 'pt-BR';
    default:
      return 'en';
  }
};

export const getFreshdeskLinkWithLocale = () => {
  const locale = getFreshdeskWidgetLocale();
  return `https://nasacademy.freshdesk.com/${locale}/support/tickets/new`;
};

export const openSupportPageWithCommunityLink = (communitySlug) => {
  // Some community slugs returned from public pages come with '/' in front while some doesn't '/'
  const checkIfCommunitySlugSlash =
    communitySlug && communitySlug[0] === '/' ? '' : '/';
  const communityLink = `https://nas.io${checkIfCommunitySlugSlash}${communitySlug}`;

  const helpDeskLink = getFreshdeskLinkWithLocale();

  openInNewTab(`${helpDeskLink}?communityLink=${communityLink}`);
};

export const localizedFormLabels = {
  es: {
    banner: '¡Estamos aquí para ayudar!',
    launcher: 'Obtener ayuda',
    contact_form: {
      title: '¿Necesitas ayuda con tu compra?',
      submit: 'Enviar',
      confirmation:
        '¡Gracias! Responderemos a tu mensaje en un día laborable'
    }
  },
  'pt-BR': {
    banner: 'Estamos aqui para ajudar!',
    launcher: 'Obter ajuda',
    contact_form: {
      title: 'Precisa de ajuda com sua compra?',
      submit: 'Enviar',
      confirmation:
        'Obrigado! Responderemos à sua mensagem em até um dia útil.'
    }
  }
};
